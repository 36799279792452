<template>
    <EditBase :loading="loading" :edicao="edicao" :nome="usuario.nome" placeholder="Novo usuário">
        <form  @submit="formSalvar" v-if="!loading">
            <div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="i_nome">Nome *</label>
                        <input type="text" required="required" :disabled="saving" class="form-control" id="i_nome" placeholder="Exemplo: João Silva" v-model="usuario.nome" :readonly="usuario.id === 1" data-cy="Nome">
                    </div>
                    <div class="form-group col-md-6">
                        <label for="i_email">Email *</label>
                        <input type="email" maxlength="120" required="required" :disabled="saving" class="form-control" id="i_email" placeholder="Exemplo: joao@email.com" v-model="usuario.email" data-cy="Email">
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-6">
                        <label for="i_telefone">Telefone {{usuario.notificacoesTelefone  ? '*' : ''}}</label>
                        <phone-input :required="usuario.notificacoesTelefone" :disabled="saving" class="form-control" id="i_telefone" placeholder="Exemplo: (00) 90000-0000" v-model="usuario.telefone" data-cy="Telefone" />
                    </div>
                    <div class="form-group col-md-6" data-cy="Notificações">
                        <label for="i_notficacao">Notificações</label>
                        <div class="mt-04">
                            <div class="custom-control custom-checkbox" style="display: inline-block;">
                                <input type="checkbox" class="custom-control-input" id="i_notificacoes_email" v-model="usuario.notificacoesEmail" data-cy="NotificaçõesEmail">
                                <label class="custom-control-label" for="i_notificacoes_email">E-mail</label>
                            </div>
                            <div class="custom-control custom-checkbox ml-4" style="display: inline-block;">
                                <input type="checkbox" class="custom-control-input " id="i_notificacoes_telefone" v-model="usuario.notificacoesTelefone" data-cy="NotificaçõesSMS">
                                <label class="custom-control-label" for="i_notificacoes_telefone">WhatsApp</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-4">
                        <label for="i_senha">Senha {{edicao ? '' : '*'}}</label>
                        <input
                            type="password"
                            :required="!edicao"
                            :disabled="saving || (!editingSelf && edicao)"
                            class="form-control"
                            id="i_senha"
                            placeholder="Digite sua senha"
                            v-model="usuario.senha"
                            data-cy="Senha"
                            @focus="infoSenha=true"
                            @blur="infoSenha=false"
                            pattern="(((?=.*\d)(?=.*[a-z])(?=.*[A-Z]))|((?=.*\d)(?=.*[a-z])(?=.*[^\s\w\d]))|((?=.*\d)(?=.*[^\s\d\w])(?=.*[A-Z]))|((?=.*[^\s\d\w])(?=.*[a-z])(?=.*[A-Z]))).{8,}"
                            title="A senha precisa conter no mínimo 8 characters e pelo menos um caractere de 3 das 4 categorias a seguir: letra minúscula, letra maiúscula, número e caractere especial."
                        >
                    </div>
                    <div class="form-group col-md-4">
                        <label for="i_senha2">Confirmação de senha {{usuario.senha ? '*' : ''}}</label>
                        <input type="password" :disabled="!usuario.senha || saving" class="form-control" id="i_senha2" placeholder="Confirme a senha" v-model="usuario.senha2">
                    </div>
                    <div v-if="edicao" class="custom-control custom-checkbox ml-4 mt-custom">
                        <input type="checkbox" class="custom-control-input " id="i_ativo" v-model="usuario.ativo" :disabled="editingSelf || usuario.id === 1" data-cy="Ativo">
                        <label class="custom-control-label" for="i_ativo">Usuário está ativo</label>
                    </div>
                </div>
                <div class="mb-2" v-show="infoSenha">
                    <div>A senha precisa conter no mínimo 8 caracteres e pelo menos um caractere de 3 das 4 categorias a seguir:</div>
                    <span :class="{ 'text-success': /[a-z]/.test(usuario.senha) }">letra minúscula</span>,
                    <span :class="{ 'text-success': /[A-Z]/.test(usuario.senha) }">letra maiúscula</span>,
                    <span :class="{ 'text-success': /[0-9]/.test(usuario.senha) }">número</span>,
                    <span :class="{ 'text-success': /[^\s\d\w]/.test(usuario.senha) }">caractere especial</span>
                </div>
                <div class="mb-2 text-danger d-flex align-items-center" v-if="senhasDiferem">
                    <x-circle-icon class="mr-1"/> As senhas diferem
                </div>
                <label for="i_grupo">Grupo de usuário *</label>
                <div class="d-flex">
                    <multiselect id="i_grupo" class="flex-grow-1" :disabled="loadingListaGruposUsuarios || saving || usuario.id === 1" v-model="usuario.grupo" :show-labels="false" :allow-empty="false" :searchable="true" :options="gruposUsuarios" label="nome" data-cy="Grupo de usuário" track-by="id"></multiselect>
                    <router-link :to="{ name: 'gruposusuarios' }" class="btn btn-secondary btn-custom text-white align-self-center flex-grow-1 ml-1" :disabled="saving || loading" type="button" style="line-heigth: 1.5px">Gerenciar</router-link>
                </div>
                <hr />
                <p>Areas para as quais receber notificação:</p>
                <Tree selectable :loading="loadingListaAreas" :items="items"/>

                <p class="small text-right">Campos marcados com * são obrigatórios.</p>
            </div>
            <div v-if="edicao" class="d-flex justify-content-between">
                <button class="btn btn-danger align-self-center lg" type="button" @click="remover" :disabled="loading || saving || !edicao" data-cy="Remover">Remover</button>
                <button class="btn btn-success align-self-center lg" type="submit" :disabled="loading || saving || !isComplete" data-cy="Salvar">Salvar</button>
            </div>
            <div v-else class="d-flex justify-content-end">
                <button class="btn btn-success align-self-center lg" type="submit" :disabled="loading || saving || !isComplete" data-cy="Salvar">Salvar</button>
            </div>
        </form>
    </EditBase>
</template>

<script>

function options (timeout) {
    return {
        timeout: timeout || 2000,
        showProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true
    }
}

function checkNull (item, v=true) {
    item.checked = v;
    for (let it of item.children) checkNull(it, null);
    return item;
}

function openParent (item) {
    if (item.parent) {
        item.parent.opened = true;
        openParent(item.parent)
    }
    return item;
}

import { copyProps, TRY_TIMEOUT } from '@/helpers/common.js';

import loginService from '../../services/login';

import axios from 'axios'
import api from '@/api.js'

import EditBase from '@/templates/EditBase'
import PhoneInput from '@/components/PhoneInput'
import Tree from '@/components/TreeArea';

export default {
    name: 'AddEditUsuario',
    components: {
        EditBase,
        PhoneInput,
        Tree,
    },
    data () {
        return {
            usuario: {
                id: null,
                nome: '',
                email: '',
                telefone: '',
                ativo: true,
                notificacoesEmail: false,
                notificacoesTelefone: false,
                senha: '',
                senha2: '',
                grupo: null,
                idGrupoUsuario: 0,
                areas: [],
            },
            infoSenha: false,
            loading: true,
            edicao: false,
            saving: false,
            editingSelf: true,
            loadingListaGruposUsuarios: false,
            loadingListaAreas: true,
            listaGruposUsuarios: [],
            listaAreas: [],
            items: [],
        }
    },
    computed: {
        isComplete () {
            let t=this.usuario;
            return !!(t.email && t.nome && t.grupo && typeof t.grupo.id !== 'undefined') && (!this.edicao && t.senha || this.edicao) && (this.usuario.senha ? this.usuario.senha === this.usuario.senha2 : true);
        },
        gruposUsuarios () {
            return this.listaGruposUsuarios.map(gu => ({
                id: gu.id,
                nome: gu.nome,
            }));
        },
        senhasDiferem() {
            return this.usuario.senha && this.usuario.senha2 && this.usuario.senha !== this.usuario.senha2;
        }
    },
    methods: {
        updateListaGruposUsuarios () {
            this.loadingListaGruposUsuarios = true;
            return axios.get(api.v1.gruposUsuarios.list(1, -1)).then(res => {
                this.listaGruposUsuarios = res.data.rows;
            }).catch(reason => {
                this.errMsg = reason.response.data?.error ?? reason.toString();
            }).then(() => {
                this.loadingListaGruposUsuarios = false;
            });
        },
        async updateListaAreas () {
            this.loadingListaAreas = true;
            let areas = (await axios.get(api.v1.area.listRecursive(''))).data;
            let treeNodes = {};
            let forest = [];
            for (let area of areas) {
                if (area.idAreaPai == null) {
                    forest.push(area);
                } else if (treeNodes[area.idAreaPai]) {
                    treeNodes[area.idAreaPai].push(area);
                } else {
                    treeNodes[area.idAreaPai] = [area];
                }
            }
            for (let area of areas) {
                delete area.idAreaPai;
                delete area.createdAt;
                delete area.updatedAt;
                area.opened = false;
                area.checked = false;
                area.editing = false;
                area.children = treeNodes[area.id] || [];
                for (let a of area.children) {
                    a.parent = area;
                }
            }
            for (let area of areas) {
                area.children.sort((a,b) => {
                    let folder = (b.children.length > 0) - (a.children.length > 0);
                    return folder ? folder : a.nome.localeCompare(b.nome);
                });
                if (this.usuario.areas.find(a => a.id === area.id)) {
                    checkNull(area);
                    openParent(area);
                }
            }
            this.listaAreas = areas;
            this.items = forest;
            this.loadingListaAreas = false;
        },
        getUsuario () {
            let id = this.$route.params.id;
            this.edicao = !!id;
            this.editingSelf = this.edicao && ((loginService.getUser()||{}).id === +id);
            this.loading = this.edicao; // se for adição loading=false;
            this.limparCampos();
            if (this.edicao) {
                return axios.get(api.v1.usuario.get(id))
                .then(res => {
                    if (res.data == null) { // usuario não existe
                        this.$snotify.error('O usuário solicitado não existe.', 'Erro');
                        this.$router.replace('/usuarios');
                    }
                    let usuario = copyProps(res.data)(['id', 'nome', 'email', 'telefone', 'notificacoesEmail', 'notificacoesTelefone', 'senha', 'ativo', 'idGrupoUsuario', 'areas']);
                    return this.updateListaGruposUsuarios().then(() => {
                        usuario.grupo = this.gruposUsuarios.find(c => c.id === usuario.idGrupoUsuario);
                        this.selectedAreas = usuario.areas.map(a => ({ value: a.id, text: a.nome }));
                        this.usuario = usuario;
                        this.loading = false;
                    });
                })
                .catch(() => {
                    this.$snotify.warning('Não foi possível buscar o usuário, tentando novamente...', 'Erro');
                    setTimeout(() => this.getUsuario(), TRY_TIMEOUT);
                })
            }
            else return Promise.resolve();
        },
        salvar () {
            this.saving = true;
            this.$snotify.async('Aguarde...', 'Salvando', () => new Promise((resolve, reject) => {
                this.usuario.idGrupoUsuario = this.usuario.grupo && this.usuario.grupo.id;
                let payload = copyProps(this.usuario)(['id', 'nome', 'email', 'telefone', 'notificacoesEmail', 'notificacoesTelefone', 'senha', 'ativo', 'idGrupoUsuario']);
                payload.areas = this.listaAreas.filter(a => a.checked).map(a => a.id);
                if (payload.telefone.endsWith('+55')) payload.telefone = null;
                let req = this.edicao ? axios.put(api.v1.usuario.update, payload)
                                      : axios.post(api.v1.usuario.create, payload);
                req.then(() => {
                    this.saving = false;
                    if (!this.edicao) {
                        this.limparCampos();
                    } else {
                        this.$router.push('/usuarios');
                    }
                    resolve({
                        title: 'Sucesso!',
                        body: 'Usuário salvo',
                        config: options()
                    })
                }).catch(e => {
                    this.saving = false;
                    reject({
                        title: 'Erro ao salvar',
                        body: e.response.data ? e.response.data.error : e.toString(),
                        config: options(10000)
                    })
                });
            }));
        },
        formSalvar (e) {
            e.preventDefault();
            this.salvar();
            return false;
        },
        remover () {
            const doDelete = () => {
                return axios.delete(api.v1.usuario.destroy, { data: { id: this.usuario.id } })
               .then(res => {
                    return res.data.error ? Promise.reject(res.data.error) : res.data;
                })
                .catch(reason => {
                    if(reason.response && reason.response.data && reason.response.data.error) {
                        this.$swal.showValidationMessage('Falha ao excluir: ' + (reason.response.data.error));
                    }
                    else this.$swal.showValidationMessage('Falha ao excluir: ' + (reason ? reason.toString() : 'motivo desconhecido.'));
                })
            };

            // TODO: transformar em função auxiliar para evitar duplicação de código
            this.$swal.fire({
                title: 'Você tem certeza?',
                text: "A remoção de um usuário é irreversível",
                type: 'warning',
                showCancelButton: true,
                reverseButtons: true,
                focusCancel: true,
                confirmButtonColor: '#dc3545',
                confirmButtonText: 'Remover',
                cancelButtonText: 'Cancelar',
                allowOutsideClick: () => !this.$swal.isLoading(),
                showLoaderOnConfirm: true,
                preConfirm: doDelete
            }).then(res => {
                if (res.value) {
                    this.$router.replace('/usuarios');
                    this.$snotify.success('Usuário removido', 'Sucesso!');
                }
            })
        },

        limparCampos() {
            this.listaAreas.forEach(el => {
                el.opened = false;
                el.checked = false;
            });
            this.usuario = {
                id: null,
                nome: '',
                email: '',
                telefone: '',
                ativo: true,
                notificacoesEmail: false,
                notificacoesTelefone: false,
                senha: '',
                grupo: null,
                idGrupoUsuario: 0,
                areas: [],
            };
        },
    },
    watch: {
        '$route': 'getUsuario',
    },
    async mounted () {
        this.updateListaGruposUsuarios();
        await this.getUsuario();
        this.updateListaAreas();
    },
}
</script>

<style scoped>
    .mt-custom {
        margin-top: 40px;
    }
    .mt-04 {
        margin-top: 0.4rem;
    }
    .btn-custom {
        line-height: 1.85em;
    }


</style>
