<template>
    <GenericList
        ref="lista"
        name="usuário"
        title="usuários"
        data-table-state-name="Usuario"
        add-route="add_usuario"
        edit-route="edit_usuario"
        :cols=" ['#','Nome', 'Email', 'Telefone', 'Grupo', 'Ativo', 'Notificações', 'Notificações para áreas']"
        :cols-name="['id','nome', 'email', 'telefone', 'idGrupoUsuario', 'ativo', null, null]"
        :cols-map="i => [i.id, i.nome, i.email, parseE164(i.telefone), { id: i.idGrupoUsuario, nome: i.grupoUsuario.nome, rota: 'edit_grupousuario' }, i.ativo ? 'Sim' : 'Não', parseNotificacoes(i.notificacoesEmail, i.notificacoesTelefone), i.areas]"
        :api="api"
        :showInativos="showInativos"
    >
        <div>
            <div>
                <router-link :to="{ name: 'gruposusuarios' }" class="btn btn-success text-white ml-1 mb-1 align-self-center" style="min-width: 116px;" data-cy="Gerenciar grupos de usuários">Gerenciar grupos de usuários</router-link>
                <router-link :to="{ name: 'add_usuario'}" class="btn btn-success text-white ml-1 mb-1 align-self-center" data-cy="Adicionar usuário">Adicionar usuário</router-link>
            </div>
            <div class="d-flex align-items-center ml-1">
                <p class="m-0">Mostrar usuários inativos</p>
                <input class="ml-2" type="checkbox" v-model="showInativos" />
            </div>
        </div>


        <template #[notificacoes]="{ value }">
                <span v-if="value.length === 0" >Nenhuma</span>
                <a v-else href="#" @click.prevent="showModal(value)" title="Exibir lista"><eye-icon size="16" /> Ver seleção</a>
        </template>
    </GenericList>
</template>

<style scoped>
    @media (max-width: 390px) {
        a {
            font-size: 14px;
        }
    }
</style>

<script>
import GenericList from '@/components/GenericList'
import api from '@/api.js'

export default {
    components: {
        GenericList
    },
    data () {
        return {
            showInativos: false,
            api: api.v1.usuario.list,
            notificacoes: 'Notificações para áreas',
        };
    },
    watch: {
        showInativos: async function () {
            await this.$refs['lista'].refreshProps();
            await this.$refs['lista'].update();
        }
    },
    methods: {
        parseE164 (phone) {
            if (!phone) return '-'
            let p = phone.replace(/[^\d]/g, '');
            return `(${p.substr(2,2)}) ${p.substr(4,5)}-${p.substr(9)}`;
        },
        parseNotificacoes (email, sms) {
            let n = [];
            if (email) n.push('Email');
            if (sms) n.push('WhatsApp');
            return n.length ? n.join(', ') : 'Nenhuma';
        },
        showModal (areas) {
            this.$swal({
                title: 'Notificações de alarmes',

                html: '<table class="table"><tr><th scope="col">Área</th></tr>' +
                (areas.map(a => `<tr><td>${a.nome}</td></tr>`).join('') || '<tr><td>(nenhuma)</td></tr>') +
                '</table><p>E todas as subáreas das áreas listadas.</p>',

                confirmButtonText: 'Fechar',
                confirmButtonColor: '#6c757d',
            })
        }
    },
    mounted () {
        this.$refs['lista'].update();
    }
}
</script>
